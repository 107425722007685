<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar color="transparent" flat>
          <v-toolbar-title>Device List</v-toolbar-title>
          <v-spacer />
          <v-switch v-model="showOffline" label="Show Offline Devices" />
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="device in filteredDevices" :key="device._id">
        <v-card :to="device._id" elevation="5">
          <div class="d-flex justify-center align-center">
            <v-icon class="grey--text my-10" size="128">{{ device.isOnline ? 'mdi-monitor' : 'mdi-monitor-off'}}</v-icon>
          </div>
          <div class="text-h5 text-center pb-10">{{ device.name }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="!filteredDevices.length">
        <v-alert icon="mdi-monitor-off">There are no available devices now.</v-alert>
      </v-col>
    </v-row>
    <v-row v-if="$store.state.auth.user.role === 'admin'">
      <v-col cols="12">
        <v-toolbar color="transparent" flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer />
          <div class="d-flex align-center justify-center mr-4">
            <v-switch class="mt-5" v-model="showOfflineUsers" label="Show Offline Users" />
          </div>
          <div class="d-flex align-center">
            <v-dialog
              v-model="dialog"
              persistent
              max-width="330"
            >
              <template v-slot:activator="{ on, attrs }">
                 <v-btn outlined v-bind="attrs" v-on="on"><v-icon left>mdi-link-variant-plus</v-icon> Generate Link</v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Generate Login Link
                </v-card-title>
                <v-card-text>
                  <v-text-field v-if="!newLink" outlined label="Name" name="name" v-model="newName"></v-text-field>
                  <v-textarea v-else v-model="newLink" outlined readonly></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="dialogClose()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                     v-if="!newLink"
                    @click="getLink(newName)"
                  >
                    Generate
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-toolbar>
        <v-simple-table dark>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in filteredUsers"
                :key="item._id"
              >
                <td>
                  <v-badge class="mr-4" dot :value="item.isOnline" color="green"></v-badge>
                  {{ item.name || item.email }}
                  <v-chip class="ml-4" label v-if="item.role === 'admin'" small>admin</v-chip>
                </td>
                <td class="text-right">
                  <v-btn v-if="item.role !== 'admin'" dense text @click="getLink(item.name)">Get Link</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { keyBy } from 'lodash'

export default {
  name: 'Index',
  data () {
    return {
      showOffline: false,
      showOfflineUsers: false,
      devices: {},
      users: {},
      dialog: false,
      newLink: '',
      newName: '',
    }
  },
  sockets: {
    devices(data) {
      this.devices = keyBy(data, '_id')
    },
    device (data) {
      this.$set(this.devices, data._id, data)
    },
    users (data) {
      this.users = keyBy(data, '_id')
    },
    user (data) {
      this.$set(this.users, data._id, data)
    }
  },
  computed: {
    filteredDevices () {
      let devices = Object.values(this.devices)
      if (this.$store.state.auth.user.role !== 'admin') {
        devices = devices.filter(i => i.visibleTo.includes(this.$store.state.auth.user._id))
      }
      if (!this.showOffline) {
        devices = devices.filter(i => !!i.isOnline)
      }
      return devices
    },
    filteredUsers () {
      let users = Object.values(this.users)
      if (!this.showOfflineUsers) {
        users = users.filter(i => !!i.isOnline)
      }
      return users
    }
  },
  created () {
    this.$socket.client.emit('getDevices')
    this.$socket.client.emit('getUsers')
  },
  methods: {
    dialogClose() {
      this.newLink = ''
      this.dialog = false
      this.newName = ''
    },
    async getLink (name) {
      const { data } = await this.$axios.post('/users', {
        token: this.$store.state.auth.token,
        name
      })
      const url = new URL('/login', window.location.origin)
      for (const [key, value] of Object.entries(data.query)) {
        url.searchParams.append(key, value)
      }
      this.newLink = url.toString()
      this.dialog = true
      // console.log('link:', url.toString())
    }
  }
}
</script>

<style>

</style>
